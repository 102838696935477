<template>
  <!--  <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  <div class="w-full  bg-black h-screen main_bg">
    <div class="loading-area">
      <div class="normal-loading">
      </div>
      <div class="loading-text">Loading</div>
    </div>
  </div>
</template>

<script setup>
// import { getCurrentInstance, onMounted } from "vue";
import { onMounted } from "vue";


const urls = [
  'https://www.hh88vip1.com/',
  'https://www.hh88vip2.com/',
  'https://www.hh88vip3.com/',
  'https://www.hh88vip4.com/',
  'https://www.hh88vip5.com/',
  // 更多 URL
];

const checkResponseTime = (url) => {
  return new Promise(resolve => {
    const start = performance.now();
    fetch(url).then(() => {
      const duration = performance.now() - start;
      resolve({ url, duration });
    }).catch(error => {
      resolve({ url, duration: Infinity, error });
    });
  });
};
// const instance = getCurrentInstance()
// let { proxy } = instance
const findFastestUrl = (urls) => {
  // const promises = urls.map(url => checkResponseTime(proxy.$isMobile() ? url.replace('www', 'm') : url));
  const promises = urls.map(url => checkResponseTime(url));
  return Promise.race(promises);
};


onMounted(() => {
  setTimeout(() => {
    findFastestUrl(urls).then(fastest => {
      // console.log('响应最快的 URL:', fastest.url, '，耗时:', fastest.duration, '毫秒');
      window.location.href = fastest.url + window.location.search;
    }).catch(error => {
      console.error('请求出错:', error);
    });
  }, 1000)
})

</script>
<style scoped>
.my-loading {
  :deep .van-loading__circular circle {
    stroke-width: 1px
  }
}
.loading-area {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 700px) {
  .main_bg {
    position: relative;
    background-image: url('@/assets/loading.webp');
    background-size: cover;
    background-repeat: no-repeat;
  }
}


@media (min-width: 701px) and (max-width: 1440px) {
  .main_bg {
    position: relative;
    background-image: url('@/assets/loading_md.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
@media  (min-width: 1441px) {
  .main_bg {
    position: relative;
    background-image: url('@/assets/loading_md.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}


@media (max-height: 700px) {
  .loading-area{
    bottom: 12%;
  }
}
@media (min-height: 701px) and (max-height: 1023px) {
  .loading-area{
    bottom: 18%;
  }
}
@media (min-height: 1024px) {
  .loading-area{
    bottom: 14%;
    scale: 1.2;
  }
}


.loading-text {
  width: 80px;
  color: #fff;
  margin-left: 30px;
}

.loading-text::after {
  content: "";
  animation: loading 3s infinite;
}

.normal-loading {
  --d: 12px;
  width: 2px;
  height: 2px;
  border-radius: 100%;
  color: #fff;
  box-shadow:
    calc(1*var(--d)) calc(0*var(--d)) 0 0,
    calc(0.707*var(--d)) calc(0.707*var(--d)) 0 0.5px,
    calc(0*var(--d)) calc(1*var(--d)) 0 1px,
    calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 1.5px,
    calc(-1*var(--d)) calc(0*var(--d)) 0 2px,
    calc(-0.707*var(--d)) calc(-0.707*var(--d))0 2.5px,
    calc(0*var(--d)) calc(-1*var(--d)) 0 3px;
  animation: l27 1s infinite steps(8);
}

@keyframes l27 {
  100% {
    transform: rotate(1turn)
  }
}

@keyframes loading {
  0% {
    content: "";
  }

  33% {
    content: ".";
  }

  66% {
    content: "..";
  }

  100% {
    content: "...";
  }
}

.content div {
  margin: 20px 0;
}


.content ul li {
  list-style-type: disc;
  list-style-position: inside;
  margin: 20px 0;
}
</style>
